import AppClient from '../AppClient';

import { GetPlans } from './interface';

export default class PlannerService {
  private basePath = process.env.VUE_APP_PAYMENT_API;
  private isSandbox = process.env.VUE_APP_SANDBOX_PAYMENT_API;
  private showForSale = process.env.VUE_APP_SHOW_FOR_SALE;

  private Client = new AppClient({ url: this.basePath });

  async getPlans(data: GetPlans) {
    const URI = '/plans';

    const response = await this.Client.get(URI, {
      isSandbox: this.isSandbox,
      showForSale: this.showForSale,
      ...data,
    });

    return response?.data;
  }

  async getPlan(data: GetPlans) {
    const URI = `plans/${data.planId}`;

    const response = await this.Client.get(URI, {
      isSandbox: this.isSandbox,
      ...data,
    });

    return response?.data;
  }

  async getPlanProducts(planId: number) {
    const URI = `plans/${planId}/products`;

    const response = await this.Client.get(URI, {
      isSandbox: this.isSandbox,
    });

    return response?.data;
  }
}
