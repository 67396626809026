





















import { Component, Vue, Prop } from 'vue-property-decorator';

const DEFAULT_FILL = 'var(--feedbackcolor-error-pure)';

@Component
export default class NotIncluded extends Vue {
  @Prop({ default: DEFAULT_FILL }) fill!: string;
}
