import { render, staticRenderFns } from "./DescriptionsLoading.vue?vue&type=template&id=a8808c9e&scoped=true&"
import script from "./DescriptionsLoading.vue?vue&type=script&lang=ts&"
export * from "./DescriptionsLoading.vue?vue&type=script&lang=ts&"
import style0 from "./DescriptionsLoading.scss?vue&type=style&index=0&id=a8808c9e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8808c9e",
  null
  
)

export default component.exports